
import { Component, Vue } from 'vue-property-decorator';
import SectionQuestionnaire from '@/components/layout/sections/SectionQuestionnaire.vue';
import ArrowButton from '@/components/HowToReturn/ArrowButton.vue';
import LmsCalculatorLight from '@/components/calculator/CalculatorLight.vue';
import CardCalculator from '@/components/calculator/CardCalculator.vue';
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';

@Component({
  name: 'AllReturnMethods',
  components: {
    PaymentMethods,
    CardCalculator,
    LmsCalculatorLight,
    ArrowButton,
    SectionQuestionnaire
  }
})
export default class AllReturnMethods extends Vue {}
