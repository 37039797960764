
import { Component, Vue, Prop } from 'vue-property-decorator';
import NumberedList from '@/components/NumberedList.vue';
import { StepItem } from '@/components/HowToReturn/StepList/types';

@Component({
  name: 'StepList',
  components: { NumberedList }
})
export default class StepList extends Vue {
  @Prop({ default: () => [] })
  steps: StepItem[];
}
