
import { Component, Vue } from 'vue-property-decorator';
import PaymentMethods from './HowToReturn/PaymentMethods.vue';
import { CreateElement, VNode } from 'vue';

@Component({
  name: 'NumberedList',
  components: { PaymentMethods }
})
export default class NumberedList extends Vue {
  getDefaultSlot() {
    return this.$slots.default || [];
  }

  render(h: CreateElement): VNode {
    return h('ol', { class: 'numbered-list' }, [
      ...this.getDefaultSlot().map(step => {
        return [h('li', {}, [h('div', { class: 'numbered-list__item' }, [step])])];
      })
    ]);
  }
}
