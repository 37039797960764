
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';
import { Component, Vue } from 'vue-property-decorator';
import StepList from '@/components/HowToReturn/StepList/StepList.vue';
import { StepItem } from '@/components/HowToReturn/StepList/types';

@Component({
  name: 'Kassa24Terminal',
  components: { StepList, PaymentMethods }
})
export default class Kassa24Terminal extends Vue {
  paymentMethod: PAYMENT_METHODS = PAYMENT_METHODS.KASSA_24_TERMINAL;

  get steps(): StepItem[] {
    return [
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP1').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/1.png')
      ]),
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP2').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/2.png')
      ]),
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP3').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/3.png')
      ]),
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP4').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/4.png')
      ]),
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP5').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/5.png')
      ]),
      new StepItem(this.$t('RETURN_BY_KASSA_24_TERMINAL.STEPS.STEP6').toString(), [
        require('@/assets/img/payment-methods/kassa24-terminal/6.png'),
        require('@/assets/img/payment-methods/kassa24-terminal/7.png')
      ])
    ];
  }
}
