
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';
import { Component, Vue } from 'vue-property-decorator';
import { StepItem } from '@/components/HowToReturn/StepList/types';
import StepList from '@/components/HowToReturn/StepList/StepList.vue';

@Component({
  name: 'QiwiWallet',
  components: { StepList, PaymentMethods }
})
export default class QiwiWallet extends Vue {
  paymentMethod: PAYMENT_METHODS = PAYMENT_METHODS.QIWI_WALLET;

  get steps(): StepItem[] {
    return [
      new StepItem(this.$t('RETURN_BY_QIWI_WALLET.STEPS.STEP1').toString(), [
        require('@/assets/img/payment-methods/qiwi-wallet/1.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_QIWI_WALLET.STEPS.STEP2').toString(), [
        require('@/assets/img/payment-methods/qiwi-wallet/2.jpg'),
        require('@/assets/img/payment-methods/qiwi-wallet/3.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_QIWI_WALLET.STEPS.STEP3').toString(), [
        require('@/assets/img/payment-methods/qiwi-wallet/4.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_QIWI_WALLET.STEPS.STEP4').toString(), [
        require('@/assets/img/payment-methods/qiwi-wallet/5.jpg')
      ])
    ];
  }
}
