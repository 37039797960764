
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';
import { Component, Vue } from 'vue-property-decorator';
import { StepItem } from '@/components/HowToReturn/StepList/types';
import StepList from '@/components/HowToReturn/StepList/StepList.vue';

@Component({
  name: 'PaymentBankDesk',
  components: { StepList, PaymentMethods }
})
export default class PaymentBankDesk extends Vue {
  paymentMethod: PAYMENT_METHODS = PAYMENT_METHODS.PAYMENT_BANK_DESK;

  get steps(): StepItem[] {
    return [
      new StepItem(this.$t('RETURN_BY_PAYMENT_BANK_DESK.STEPS.STEP1').toString()),
      new StepItem(this.$t('RETURN_BY_PAYMENT_BANK_DESK.STEPS.STEP2').toString()),
      new StepItem(this.$t('RETURN_BY_PAYMENT_BANK_DESK.STEPS.STEP3').toString()),
      new StepItem(this.$t('RETURN_BY_PAYMENT_BANK_DESK.STEPS.STEP4').toString())
    ];
  }
}
