
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import ArrowButton from '@/components/HowToReturn/ArrowButton.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';

interface PaymentMethod {
  name: PAYMENT_METHODS;
  iconSrc: string;
  text: string;
  route: Location;
}

@Component({
  name: 'PaymentMethods',
  components: { ArrowButton }
})
export default class PaymentMethods extends Vue {
  get allPaymentMethods(): PaymentMethod[] {
    return [
      {
        name: PAYMENT_METHODS.ONLINE_BANKING,
        iconSrc: require('@/assets/img/payment-methods/bank.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.ONLINE_BANKING').toString(),
        route: { name: 'OnlineBanking' }
      },
      {
        name: PAYMENT_METHODS.KASSA_24_TERMINAL,
        iconSrc: require('@/assets/img/payment-methods/kassa24.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.KASSA_24_TERMINAL').toString(),
        route: { name: 'Kassa24Terminal' }
      },
      {
        name: PAYMENT_METHODS.CARD_PAYMENT,
        iconSrc: require('@/assets/img/payment-methods/processing.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.CARD_PAYMENT').toString(),
        route: { name: 'CardPayment' }
      },
      {
        name: PAYMENT_METHODS.QIWI_TERMINAL,
        iconSrc: require('@/assets/img/payment-methods/qiwi.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.QIWI_TERMINAL').toString(),
        route: { name: 'QiwiTerminal' }
      },
      {
        name: PAYMENT_METHODS.QIWI_WALLET,
        iconSrc: require('@/assets/img/payment-methods/qiwi.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.QIWI_WALLET').toString(),
        route: { name: 'QiwiWallet' }
      },
      {
        name: PAYMENT_METHODS.PAYMENT_BANK_DESK,
        iconSrc: require('@/assets/img/payment-methods/bank.png'),
        text: this.$t('HOW_TO_RETURN.METHODS.PAYMENT_BANK_DESK').toString(),
        route: { name: 'PaymentBankDesk' }
      }
    ];
  }

  @Prop({
    default: () => []
  })
  hiddenMethods: PAYMENT_METHODS[];

  @Prop({
    default: false
  })
  inline: boolean;

  get paymentMethods(): PaymentMethod[] {
    return this.allPaymentMethods.filter(method => !this.hiddenMethods.includes(method.name));
  }
}
