
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';
import { Component, Vue } from 'vue-property-decorator';
import StepList from '@/components/HowToReturn/StepList/StepList.vue';
import { StepItem } from '@/components/HowToReturn/StepList/types';

@Component({
  name: 'OnlineBanking',
  components: { StepList, PaymentMethods }
})
export default class OnlineBanking extends Vue {
  paymentMethod: PAYMENT_METHODS = PAYMENT_METHODS.ONLINE_BANKING;

  get steps(): StepItem[] {
    return [
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP1').toString()),
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP2').toString(), [
        require('@/assets/img/payment-methods/online-banking/1.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP3').toString(), [
        require('@/assets/img/payment-methods/online-banking/2.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP4').toString(), [
        require('@/assets/img/payment-methods/online-banking/3.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP5').toString(), [
        require('@/assets/img/payment-methods/online-banking/4.jpg')
      ]),
      new StepItem(this.$t('RETURN_BY_ONLINE_BANKING.STEPS.STEP6').toString(), [
        require('@/assets/img/payment-methods/online-banking/5.jpg')
      ])
    ];
  }
}
