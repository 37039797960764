
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component
export default class AnchorButtonMixin extends Vue {
  @Prop() url!: string;
  @Prop() route!: Location;
  @Prop({ default: '_self' }) target!: string;
  @Prop({ default: 'button' }) type!: string;

  get tagName(): string {
    return this.url ? 'a' : this.route ? 'router-link' : 'button';
  }

  get attrs() {
    if (this.url) {
      const { url, target } = this;
      return { href: url, target };
    }
    if (this.route) {
      return { to: this.route };
    }
    return {};
  }
}
