
import PaymentMethods from '@/components/HowToReturn/PaymentMethods.vue';
import { PAYMENT_METHODS } from '@/store/modules/models/paymentMethods';
import { Component, Vue } from 'vue-property-decorator';
import StepList from '@/components/HowToReturn/StepList/StepList.vue';
import { StepItem } from '@/components/HowToReturn/StepList/types';
import { CURRENT_LOCALE } from '@/utils/cookies';

@Component({
  name: 'CardPayment',
  components: { StepList, PaymentMethods }
})
export default class CardPayment extends Vue {
  paymentMethod: PAYMENT_METHODS = PAYMENT_METHODS.CARD_PAYMENT;

  get steps(): StepItem[] {
    const locale = CURRENT_LOCALE;

    return [
      new StepItem(this.$t('RETURN_BY_CARD_PAYMENT.STEPS.STEP1').toString(), [
        require(`@/assets/img/payment-methods/processing/${locale}/1.png`)
      ]),
      new StepItem(this.$t('RETURN_BY_CARD_PAYMENT.STEPS.STEP2').toString(), [
        require(`@/assets/img/payment-methods/processing/${locale}/2.png`)
      ]),
      new StepItem(this.$t('RETURN_BY_CARD_PAYMENT.STEPS.STEP3').toString(), [
        require(`@/assets/img/payment-methods/processing/${locale}/3.png`)
      ]),
      new StepItem(this.$t('RETURN_BY_CARD_PAYMENT.STEPS.STEP4').toString(), [
        require(`@/assets/img/payment-methods/processing/${locale}/4.png`)
      ]),
      new StepItem(this.$t('RETURN_BY_CARD_PAYMENT.STEPS.STEP5').toString(), [
        require(`@/assets/img/payment-methods/processing/${locale}/5.png`)
      ])
    ];
  }
}
